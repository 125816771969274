import { FC, SVGProps } from "react";

export const AlertErrorIconSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className="fill-red-500"
    {...props}
  >
    <path d="M8.99965 13.3867C9.20331 13.3867 9.37709 13.3147 9.52098 13.1708C9.66487 13.0269 9.73681 12.8531 9.73681 12.6495C9.73681 12.4458 9.66487 12.2721 9.52098 12.1282C9.37709 11.9843 9.20331 11.9123 8.99965 11.9123C8.79599 11.9123 8.62221 11.9843 8.47832 12.1282C8.33443 12.2721 8.26249 12.4458 8.26249 12.6495C8.26249 12.8531 8.33443 13.0269 8.47832 13.1708C8.62221 13.3147 8.79599 13.3867 8.99965 13.3867ZM8.32259 10.49H9.67671V4.19999H8.32259V10.49ZM5.60022 17.2288L0.770508 12.3777V5.60022L5.59551 0.770508H12.3991L17.2288 5.59551V12.3991L12.3777 17.2288H5.60022ZM6.16111 15.8746H11.8382L15.8746 11.8382V6.16111L11.8121 2.12465H6.16111L2.12465 6.16111V11.8382L6.16111 15.8746Z" />
  </svg>
);
