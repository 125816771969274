import React from "react";

const BlueProfileSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {" "}
      <defs>
        {" "}
        <clipPath id="7cnkkbq5ra">
          {" "}
          <path
            data-name="Rectangle 563"
            transform="translate(140)"
            style={{ fill: "#fff", stroke: "#707070" }}
            d="M0 0h24v24H0z"
          ></path>{" "}
        </clipPath>{" "}
      </defs>{" "}
      <g transform="translate(-140)" style={{ clipPath: "url(#7cnkkbq5ra)" }}>
        {" "}
        <circle
          data-name="Ellipse 226"
          cx="4.5"
          cy="4.5"
          r="4.5"
          transform="translate(148 5)"
          style={{ fill: "none", stroke: "#75a7ad", strokeWidth: "2px" }}
        ></circle>{" "}
        <path
          data-name="Path 7828"
          d="M1343.163 43.38s2.935-5.14 8.192-5.14 7.923 5.14 7.923 5.14"
          transform="translate(-1199 -24.239)"
          style={{ fill: "none", stroke: "#75a7ad", strokeWidth: "2px" }}
        ></path>{" "}
      </g>
    </svg>
  );
};

export default BlueProfileSvg;
