import { createContext, useContext } from "react";
import { TranslationQuery } from "services/datocms/generated";

export const TranslationContext = createContext<
  NonNullable<TranslationQuery["translation"]>
>({});

export const useTranslationContext = () => {
  const context = useContext(TranslationContext);

  return context;
};
