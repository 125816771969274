import React from "react";

const ProfileSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <defs>
        <clipPath id="a">
          <path
            d="M0 0h24v24H0z"
            data-name="Rectangle 563"
            style={{
              fill: "#fff",
              stroke: "#707070",
            }}
            transform="translate(140)"
          />
        </clipPath>
      </defs>
      <g
        style={{
          clipPath: "url(#a)",
        }}
        transform="translate(-140)"
      >
        <circle
          cx={4.5}
          cy={4.5}
          r={4.5}
          data-name="Ellipse 226"
          style={{
            fill: "none",
            stroke: "#efebe4",
            strokeWidth: 2,
          }}
          transform="translate(148 5)"
        />
        <path
          d="M1343.163 43.38s2.935-5.14 8.192-5.14 7.923 5.14 7.923 5.14"
          data-name="Path 7828"
          style={{
            fill: "none",
            stroke: "#efebe4",
            strokeWidth: 2,
          }}
          transform="translate(-1199 -24.239)"
        />
      </g>
    </svg>
  );
};

export default ProfileSvg;
