import { FC } from "react";
import { MobileHeaderQuery } from "services/datocms/generated";
import MobileBottomHeader from "./MobileBottomHeader/MobileBottomHeader";
import st from "./MobileHeader.module.scss";
import MobileTopHeader from "./MobileTopHeader.tsx/MobileTopHeader";

export type MobileHeaderProps = {
  headerData: MobileHeaderQuery["mobileHeader"];
};

const MobileHeader: FC<MobileHeaderProps> = ({ headerData }) => {
  return (
    <header className={st.header}>
      <MobileTopHeader
        text={headerData?.promotionalBar ?? ""}
        url={headerData?.promotionalBarLink ?? ""}
        textColor={headerData?.promotionalBarTextColor?.hex ?? ""}
        backgroundColor={headerData?.promotionalBarBackgroundColor?.hex ?? ""}
      />
      <MobileBottomHeader headerData={headerData} />
    </header>
  );
};

export default MobileHeader;
