import { CartItemUpdateBundle, CartItemUpdateDto } from "lib-types/cart";
import React from "react";
import { CartFragment } from "services/graphql/generated";

export interface CartContextInterface {
  cart: CartFragment | null;
  loading: boolean;
  refreshCart(): void;
  removeDynamicBundle(id: string, cartBundleReference: number): Promise<void>;
  updateCartItem(_dto: CartItemUpdateDto): Promise<CartFragment | null>;
  updateCartWithMultipleItems(
    items: CartItemUpdateDto[],
  ): Promise<CartFragment | null>;
  updateCartItemWithBundle(
    bundle: CartItemUpdateBundle,
  ): Promise<CartFragment | null>;
  getSize(): number | undefined;
}

export const CartContext = React.createContext<CartContextInterface>({
  cart: null,
  loading: true,
  refreshCart: () => {},
  removeDynamicBundle: async (id: string, cartBundleReference: number) => {},
  updateCartItem: async (_dto: CartItemUpdateDto) => null,
  updateCartWithMultipleItems: async (items: CartItemUpdateDto[]) => null,
  updateCartItemWithBundle: async (bundle: CartItemUpdateBundle) => null,
  getSize: () => {
    return 0;
  },
});
