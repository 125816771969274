import { FC, SVGProps } from "react";

export const ChevronDownSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className="stroke-primary"
      {...props}
    >
      <path
        d="M6.5 9L12.5 15L18.5 9"
        strokeWidth="2.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDownSvg;
