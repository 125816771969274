import { Cart, CartProduct } from "./cart";
import { PartialProduct, Product } from "./product";

export enum LOGIN_LOCATION_ENUM {
  header = "header",
  checkoutModal = "checkoutModal",
  checkoutSubscriptionModal = "checkoutSubscriptionModal",
}

export interface PageViewAnalyticsData {
  event: EVENT_NAMES.pageView;
  page: {
    location: string;
    path: string;
    id: string;
    type: PAGE_TYPE_ENUM;
    title: string;
    breakpoint: SCREEN_SIZES;
    isRedirect: boolean;
    brand: string;
    referrer?: string;
    source: string;
    medium: string;
    campaign: string;
    term: string;
    content: string;
    noInteraction: false;
    pageCategory?: string;
    lang?: string;
    currency?: string;
  };
}

export interface AnalyticsData {
  event: EVENT_NAMES;
  leadType?: string;
  leadSource?: string;
  userToken?: string;
  dateOfBirth?: string;
  item?: Product | Partial<CartProduct> | null;
  qty?: number;
  page?: string;
  referrer?: string;
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  isEmployerSignUp?: boolean;
  isWeWorkSignUp?: boolean;
  noInteraction?: boolean;
  cart?: Partial<Cart> | { subtotal?: number } | AnalyticsCart;
  loginLocation?: LOGIN_LOCATION_ENUM;
  email?: string;
  pageName?: string;
  popularBrand?: string;
  bestSellerTitle?: string;
  categoryTitle?: string;
  navigationName?: string;
  oldQuantity?: number;
  newQuantity?: number;
  intervalDays?: number | null;
  modalName?: string;
  step?: number;
  category?: string;
  action?: string;
  label?: string;
  nonInteraction?: boolean;
  formId?: string;
  itemIndex?: number;
  itemListId?: string;
  itemListName?: string;
  ecommerce?: {
    value?: number | string;
    items?: PartialProduct[];
    itemListId?: string;
    itemListName?: string;
    creativeName?: string;
    promotionId?: string;
    promotionName?: string;
    creativeSlot?: string;
    currency?: string;
    item_list_id?: string;
    item_list_name?: string;
  };
  form?: {
    businessName?: string;
    businessSetting?: string;
    orderManager?: string;
    numberOfUsers?: string;
    existingCustomer?: boolean;
    formDestination?: string;
    formId?: string;
    formLength?: number;
    formName?: string;
    formSource?: FORM_SOURCE;
    formSubmitText?: string;
    formType?: FORM_TYPE;
    messageId?: string;
    formExits?: number;
    formStepId?: string;
    formStepName?: string;
  };
  formData?: {
    email_address?: string;
    accept_marketing?: string;
  };
  elementId?: string;
  elementType?: string;
  currency?: string;
}

export interface FullAnalyticsData extends Omit<AnalyticsData, "email"> {
  email: boolean | string;
  username?: string;
}

export interface AnalyticsCart {
  itemCount: number;
  totalValue: number;
  items?: Product[];
}

export enum PAGE_TYPE_ENUM {
  home = "home",
  collection = "collection",
  product = "product",
  cart = "cart",
  checkout = "checkout",
  order = "order",
  search = "search",
  account = "account",
  contact = "contact",
  support = "support",
  legal = "legal",
  other = "other",
  marketing = "marketing",
  blog = "blog",
}

export type SCREEN_SIZES = "xl" | "l" | "m" | "s" | "xs";

export type FORM_SOURCE =
  | "header"
  | "body"
  | "footer"
  | "modal"
  | "inline"
  | "slideout";
export type FORM_TYPE =
  | "request"
  | "account"
  | "newsletter"
  | "notification"
  | "survey"
  | "contact"
  | "download";
export interface FullPageViewAnalyticsData extends PageViewAnalyticsData {
  user: {
    customerId?: string;
  };
}

export interface QueryStringAttributes {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  isEmployerSignUp?: string;
  isWeWorkSignUp?: string;
  isSignup?: string;
  returnUrl?: string;
}

export enum EVENT_NAMES {
  addressVerified = "addressVerified",
  bestSellersClick = "bestSellersClick",
  bundleProductClicked = "productClicked",
  cartCheckoutButton = "cartCheckoutButtonClicked",
  cartLineItem = "cartLineItemQtyChanged",
  cartLoginRedirect = "cartLoginRedirect",
  cartOutOfStock = "cartOutOfStockErrorDisplayed",
  cartViewed = "cartViewed",
  categoryClick = "categoryClick",
  generateLead = "generateLead",
  landingPageLogin = "landingPageLogin",
  loqateAutocompleteAddressSelected = "loqate.autocompleteAddressSelected",
  loqateAutocompleteInitiated = "loqate.autocompleteInitiated",
  modalClosed = "modalClosed",
  navigationClick = "navigationClick",
  pageView = "PageView",
  popularBrandsClick = "popularBrandsClick",
  productDetailBuyButton = "productDetailBuyButtonClicked",
  productOneTimePurchaseSelected = "product.oneTimePurchaseSelected",
  productSubAndSaveSelected = "product.subAndSaveSelected",
  productTileBuyButton = "productTileBuyButtonClicked",
  productViewed = "productViewed",
  unverifiedAddressSelected = "unverifiedAddressSelected",
  userChanged = "userChanged",
  userContinueAsGuest = "userContinueAsGuest",
  userLogin = "userLogin",
  userLoginSuccess = "Login",
  userSignUp = "userSignUp",
  userSignupSuccess = "CreateAccount",
  formView = "formView",
  formStart = "formStart",
  formFail = "formFail",
  formAbandonment = "formAbandonment",
  viewItemList = "viewItemList",
  viewPromotion = "viewPromotion",
  selectItem = "SelectItem",
  selectPromotion = "selectPromotion",
  formSubmit = "FormSubmit",
  formExit = "formExit",
  productListImpression = "ProductListImpression",
  machineRegistrationSuccess = "Registration",
  productDetailView = "ProductDetailView",
  addToCart = "AddToCart",
  removeFromCart = "RemoveFromCart",
}

export interface AnalyticsSessionParamsType {
  utm_campaign?: string;
  utm_term?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_content?: string;
  page_location?: string;
}

export interface AnalyticsSessionParamsContextType {
  analyticsSessionParams?: AnalyticsSessionParamsType | null;
}

export interface LogPageViewParams {
  pageType: PAGE_TYPE_ENUM;
  pageCategory?: string;
  pageTitle?: string;
  lang?: string;
  currency?: string;
  id: string;
  sessionAtributes?: AnalyticsSessionParamsType;
}

export interface LogContextValueInterface {
  useLogPageView: (
    _params: LogPageViewParams,
    _product?: Product | null,
    _cart?: AnalyticsCart | null,
    _key?: string,
  ) => void;
  hasLoggedPageView?: boolean;
}
