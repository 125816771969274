import {
  AnalyticsCart,
  LogContextValueInterface,
  LogPageViewParams,
  Product,
} from "@shared/types";
import React, { useContext } from "react";

export const LogContext = React.createContext<LogContextValueInterface>({
  useLogPageView: (
    _params: LogPageViewParams,
    _product?: Product | null,
    _cart?: AnalyticsCart | null,
    _key?: string,
  ) => {},
});

export const useLogContext = () => {
  return useContext(LogContext);
};
