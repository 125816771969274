import { endpoints } from "config";
import { CartContext } from "context/cart";
import Link from "next/link";
import { FC, useContext, useState } from "react";
import { Image } from "react-datocms/image";
import BlueProfileSvg from "../../svg/BlueProfileSvg";
import CartSvg from "../../svg/CartSvg";
import CloseSvg from "../../svg/CloseSvg";
import MobileMenuBarSvg from "../../svg/MobileMenuBarSvg";
import SodastreamLogo from "../../svg/SodastreamLogo";
import { MobileHeaderProps } from "../MobileHeader";
import st from "./MobileBottomHeader.module.scss";

const MobileBottomHeader: FC<MobileHeaderProps> = ({ headerData }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [selectedMenuItemId, setSelectedMenuItemId] = useState("");
  const { getSize } = useContext(CartContext);

  const cartSize = getSize() || 0;

  const handleMenuOpen = () => {
    setIsMenuOpened((prev) => !prev);
    setSelectedMenuItemId("");
  };

  const renderMenuContent = () => {
    const menuItem = headerData?.mobileMenuCategories.find(
      (item) => item.id === selectedMenuItemId,
    );
    if (menuItem) {
      return (
        <div className={st.sideMenuTopContainer}>
          {menuItem.content.map((item) => {
            return (
              <Link
                bi-type="Menu"
                bi-id={item.linkText}
                key={item.id}
                className={st.sideMenuTopSingleItem}
                href={item.url ?? ""}
                style={{
                  textDecoration: "none",
                  color: item.textColor ?? "black",
                }}
                onClick={() => {
                  setIsMenuOpened(false);
                }}
              >
                <div
                  style={{
                    backgroundColor: item.backgroundColor?.hex ?? "transparent",
                    borderRadius: "20px",
                  }}
                >
                  {item.image?.responsiveImage && (
                    <Image
                      data={item.image?.responsiveImage}
                      usePlaceholder={false}
                      fadeInDuration={0}
                    />
                  )}
                </div>
                <div
                  className={st.sideMenuSingleTopImageText}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {item.linkText}
                </div>
              </Link>
            );
          })}
        </div>
      );
    } else {
      return (
        <>
          <div className={st.sideMenuTopContainer}>
            {headerData?.mobileMenuCategories.map((category) => {
              return (
                <div
                  onClick={() => setSelectedMenuItemId(category.id)}
                  key={category.id}
                  className={st.sideMenuTopSingleItem}
                >
                  {category.image?.responsiveImage && (
                    <Image
                      data={category.image?.responsiveImage}
                      usePlaceholder={false}
                      fadeInDuration={0}
                    />
                  )}
                  <span className={st.sideMenuSingleTopImageText}>
                    {category.title}
                  </span>
                </div>
              );
            })}
          </div>
          {/** TEXT ITEMS */}
          <div className={st.sideMenuTextItemsContainer}>
            {headerData?.bottomMenuLinks.map((link) => (
              <div key={link.id} className={st.sideMenuSingleItem}>
                <Link
                  bi-type="Menu"
                  bi-id={link.title}
                  href={link.url ?? ""}
                  className={st.sideMenuSingleItemText}
                  style={{ color: link.color?.hex ?? "black" }}
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {link.title}
                </Link>
              </div>
            ))}

            <div style={{ marginTop: "25px" }}>
              <Link
                bi-type="Menu"
                bi-id={headerData?.bottomMenuColoredLink[0].title}
                style={{
                  fontWeight: 700,
                  fontSize: "1rem",
                  textDecoration: "none",
                  color:
                    headerData?.bottomMenuColoredLink[0].color?.hex ?? "black",
                }}
                href={headerData?.bottomMenuColoredLink[0].url ?? ""}
                onClick={() => {
                  setIsMenuOpened(false);
                }}
              >
                {headerData?.bottomMenuColoredLink[0].title}
              </Link>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className={st.container}>
        <div onClick={handleMenuOpen} className={st.topLeft}>
          {isMenuOpened ? <CloseSvg color="#efebe4" /> : <MobileMenuBarSvg />}
        </div>
        <div className={st.topMiddle}>
          <Link href="/">
            <SodastreamLogo color="#75a7ad" width="158px" />
          </Link>
        </div>
        <div className={st.topRight}>
          <Link
            bi-type="Menu"
            bi-id="My Account"
            href={endpoints.portalFrontend ?? ""}
          >
            <BlueProfileSvg />
          </Link>
          <div className={st.cartContainer}>
            {cartSize > 0 && <div className={st.cartCount}>{cartSize}</div>}
            <Link
              bi-type="Menu"
              bi-id="Cart"
              href="/cart"
              className={st.cartLogo}
            >
              <CartSvg color="#75a7ad" />
            </Link>
          </div>
        </div>
      </div>
      <div className={st.subContainer}>
        {headerData?.customHeaderLinks.map((link) => {
          if (link.openMenu) {
            return (
              <div
                onClick={() => {
                  const category = headerData.mobileMenuCategories.find(
                    (category) =>
                      category.title?.toLocaleLowerCase() ===
                      link.title?.toLocaleLowerCase(),
                  );
                  if (category) {
                    setSelectedMenuItemId(category.id);
                    setIsMenuOpened(true);
                  }
                }}
                key={link.id}
                className={st.subContainerItem}
              >
                <span className={st.subContainerItemText}>{link.title}</span>
              </div>
            );
          } else {
            return (
              <div key={link.id} className={st.subContainerItem}>
                <Link
                  bi-type="Menu"
                  bi-id={link.title}
                  style={{
                    color: link.color?.hex ?? "black",
                  }}
                  href={link.url || ""}
                  className={st.subContainerItemText}
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {link.title}
                </Link>
              </div>
            );
          }
        })}
      </div>
      {/** SIDE MENU */}
      <div
        style={{ display: isMenuOpened ? "flex" : "none" }}
        className={st.sideMenuOverlayContainer}
      >
        <div className={st.sideMenuContentContainer}>{renderMenuContent()}</div>
      </div>
    </>
  );
};

export default MobileBottomHeader;
