import {
  ApolloClient,
  NormalizedCacheObject,
  useLazyQuery,
} from "@apollo/client";
import {
  deleteCartCookie,
  getCartIdFromCookie,
  saveCartCookie,
} from "helpers/cart";
import { CartItemUpdateBundle, CartItemUpdateDto } from "lib-types/cart";
import { useEffect, useState } from "react";
import {
  CartDocument,
  CartFragment,
  CartQuery,
  CartQueryVariables,
  GetCartFromUserDocument,
  GetCartFromUserQuery,
  GetCartFromUserQueryVariables,
  useAddExactDynamicBundleMutation,
  useAssociateCartToUserMutation,
  useRemoveDynamicBundleMutation,
  useUpdateCartItemMutation,
} from "services/graphql/generated";
import { CartContextInterface } from "../cart";

const getExistingQuantityForProductInCart = (
  productId: string,
  cart: CartFragment | null,
) => {
  if (cart) {
    for (let i = 0; i < cart.lineItems.length; ++i) {
      const lineItem = cart.lineItems[i];
      if (lineItem.__typename === "CartItem" && lineItem.item?.id === productId)
        return lineItem.quantity;
    }
  }
  return 0;
};

export function useCartProvider(
  client: ApolloClient<NormalizedCacheObject>,
  currentUserId: string | null,
): {
  cartContextValue: CartContextInterface;
} {
  const [cart, setCart] = useState<CartFragment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [updateCartItem] = useUpdateCartItemMutation({ client });
  const [associateCartToUser] = useAssociateCartToUserMutation({ client });
  const [addExactDynamicBundleMutation] = useAddExactDynamicBundleMutation({
    client,
  });
  const [removeDynamicBundleMutation] = useRemoveDynamicBundleMutation({
    client,
  });
  const [getCartFromUserId] = useLazyQuery<
    GetCartFromUserQuery,
    GetCartFromUserQueryVariables
  >(GetCartFromUserDocument, { client });
  const [getCartFromId] = useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    { client },
  );

  const fetchCart = async (cartIdFromCookie: string) => {
    try {
      const { data } = await getCartFromId({
        variables: { id: cartIdFromCookie },
      });
      if (!data?.cart) {
        deleteCartCookie();
      }
      setCart(data?.cart || null);
    } catch {
      setCart(null);
    } finally {
      setLoading(false);
    }
  };

  const getCartFromUser = async () => {
    try {
      const { data } = await getCartFromUserId();
      setCart(data?.currentUser?.cart || null);
    } catch {
      setCart(null);
    } finally {
      setLoading(false);
    }
  };

  const refreshCart = () => {
    const cartIdFromCookie = getCartIdFromCookie();

    // get cart with a cart id
    if (cartIdFromCookie) {
      fetchCart(cartIdFromCookie);
    }

    // no cart id: get cart from current user
    if (!cartIdFromCookie && currentUserId) {
      getCartFromUser();
    }

    // no cart id or current user: no cart
    if (!cartIdFromCookie && !currentUserId) {
      setCart(null);
      setLoading(false);
    }
  };

  const updateCart = async (dto: CartItemUpdateDto) => {
    if (!dto.productId) throw new Error("No productId, could not add product.");

    const cartIdFromCookie = getCartIdFromCookie();
    const {
      productId,
      quantity,
      subscriptionIntervalInDays,
      analyticsDetails,
    } = dto;

    const newQuantity = dto.includeExistingQuantity
      ? getExistingQuantityForProductInCart(dto.productId, cart) + quantity
      : quantity;

    try {
      const updatedCart = await updateCartItem({
        variables: {
          id: cartIdFromCookie,
          itemId: productId,
          quantity: newQuantity,
          subscriptionIntervalInDays,
          ...(analyticsDetails && {
            analyticsDetails: JSON.stringify(analyticsDetails),
          }),
        },
      });
      const newCart = updatedCart.data?.updateCartItem || null;
      setCart(newCart);
      return newCart;
    } catch (e) {
      // logged in errorLink
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateCartWithMultipleItems = async (dtos: CartItemUpdateDto[]) => {
    let cartIdFromCookie = getCartIdFromCookie();
    setLoading(true);
    let newCart: CartFragment | null = null;
    try {
      for (let i = 0; i < dtos.length; i++) {
        const {
          productId,
          quantity,
          subscriptionIntervalInDays,
          analyticsDetails,
          includeExistingQuantity,
        } = dtos[i];

        const newQuantity = includeExistingQuantity
          ? getExistingQuantityForProductInCart(productId, cart) + quantity
          : quantity;

        const updatedCart = await updateCartItem({
          variables: {
            id: cartIdFromCookie,
            itemId: productId,
            quantity: newQuantity,
            subscriptionIntervalInDays,
            ...(analyticsDetails && {
              analyticsDetails: JSON.stringify(analyticsDetails),
            }),
          },
        });
        newCart = updatedCart.data?.updateCartItem || null;

        // We need to set cart ID manually if it is initially missing
        if (!cartIdFromCookie) {
          cartIdFromCookie = updatedCart.data?.updateCartItem?.id;
        }

        setCart(newCart);
        saveCartCookie(updatedCart.data?.updateCartItem?.id);
      }
      return newCart;
    } catch (e) {
      // logged in errorLink
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateCartItemWithBundle = async (bundle: CartItemUpdateBundle) => {
    const cookieCartId = getCartIdFromCookie();
    const { bundleTag, analyticsDetails } = bundle;
    const bundleAdded = await addExactDynamicBundleMutation({
      variables: {
        input: {
          bundleTag,
          // quantity, THIS WILL BE IMPLEMENTED IN THE BE SO IT CAN BE USED
          ...(cart?.id && { id: cart?.id }),
          ...(analyticsDetails && {
            analyticsDetails: JSON.stringify(analyticsDetails),
          }),
        },
      },
    });
    const bundleCartId = bundleAdded.data?.addExactDynamicBundle?.id;

    if (bundleCartId) {
      if (bundleCartId !== cookieCartId) {
        saveCartCookie(bundleCartId);
      }
      refreshCart();
    }
    return bundleAdded.data?.addExactDynamicBundle ?? null;
  };

  const removeDynamicBundle = async (
    id: string,
    cartBundleReference: number,
  ) => {
    const variables = {
      input: {
        cartBundleReference,
        id,
      },
    };
    await removeDynamicBundleMutation({ variables });
    refreshCart();
  };

  // refresh cart on currentUserId and tab changes
  useEffect(() => {
    refreshCart();

    /* Event listener to refresh cart after changing tabs */
    /* TODO: debounce? */
    const refreshIfTabHasChanged = () => {
      if (document?.visibilityState === "visible") refreshCart();
    };
    window?.addEventListener("visibilitychange", refreshIfTabHasChanged);
    return function removeFocusRefresh() {
      window?.removeEventListener("visibilitychange", refreshIfTabHasChanged);
    };
    // we don't want refreshCart in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  // if an unauthenticated user creates a cart and then logs in afterward,
  // associate that cart to the logged-in user
  useEffect(() => {
    const handleAssociateCartToUser = async () => {
      try {
        const { data } = await associateCartToUser({
          variables: { cartId: cart?.id },
        });
        if (data?.associateCartToUser?.user) {
          setCart(data?.associateCartToUser);
          setLoading(false);
        }
      } catch (e) {
        // logged in errorLink
      }
    };
    if (cart && !cart.user && currentUserId) {
      handleAssociateCartToUser();
    }
    // we don't want associateCartToUser in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, currentUserId]);

  // update cookies depending on cart id changes
  useEffect(() => {
    if (!loading) {
      if (cart?.id) {
        if (cart.id !== getCartIdFromCookie()) {
          saveCartCookie(cart.id);
        }
      } else {
        // deleteCartCookie();
      }
    }
  }, [cart?.id, loading]);

  // TODO: consider using recoil.js when it is stable
  const cartContextValue: CartContextInterface = {
    cart,
    loading,
    refreshCart,
    removeDynamicBundle: (id: string, cartBundleReference: number) =>
      removeDynamicBundle(id, cartBundleReference),
    updateCartItem: (dto: CartItemUpdateDto) => updateCart(dto),
    updateCartItemWithBundle: (bundle: CartItemUpdateBundle) =>
      updateCartItemWithBundle(bundle),
    updateCartWithMultipleItems: (dtos: CartItemUpdateDto[]) =>
      updateCartWithMultipleItems(dtos),
    getSize: () => {
      return loading
        ? undefined
        : cart?.lineItems
            ?.map((lineItem) => {
              if (lineItem.__typename === "CartItem") return lineItem.quantity;
              if (lineItem.__typename === "BundleLineItem") return 1;
              return 0;
            })
            .reduce((a, b) => a + b, 0) || 0;
    },
  };

  return { cartContextValue };
}
