import { useEffect, useState } from "react";
import { TranslationQuery } from "services/datocms/generated";

export const useTranslationProvider = (
  translations: NonNullable<TranslationQuery["translation"]>,
) => {
  const [translationData, setTranslationData] = useState<
    NonNullable<TranslationQuery["translation"]>
  >(translations || {});

  useEffect(() => {
    if (translations && Object.keys(translations).length > 0) {
      if (typeof window !== "undefined") {
        localStorage.setItem("translations", JSON.stringify(translations));
      }
    }
    setTranslationData(translations);
  }, [translations]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const value = localStorage.getItem("translations") || "{}";
      setTranslationData(JSON.parse(value));
    }
  }, []);

  return translationData;
};
