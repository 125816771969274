import { FC } from "react";
import { DesktopHeaderQuery } from "services/datocms/generated";
import BottomHeader from "./BottomHeader/BottomHeader";
import st from "./Header.module.scss";
import TopHeader from "./TopHeader/TopHeader";

export type HeaderProps = {
  headerData: DesktopHeaderQuery["desktopHeader"];
};

const Header: FC<HeaderProps> = ({ headerData }) => {
  return (
    <header className={st.header}>
      <TopHeader
        text={headerData?.promotionalBar ?? ""}
        url={headerData?.promotionalBarLink ?? ""}
        textColor={headerData?.promotionalBarTextColor?.hex ?? ""}
        backgroundColor={headerData?.promotionalBarBackgroundColor?.hex ?? ""}
      />
      <BottomHeader headerData={headerData} />
    </header>
  );
};

export default Header;
