import { endpoints } from "config";
import { GraphQLClient } from "graphql-request";
import { getDatoCMSEnvironment } from "utils/datocms-environment";

export function createDatoClient(
  preview = false,
  queryParams?: string,
): GraphQLClient {
  const endpoint = preview
    ? `${endpoints.datoCmsEndpoint}/preview${
        queryParams ? `?${queryParams}` : ""
      }`
    : `${endpoints.datoCmsEndpoint}/${queryParams ? `?${queryParams}` : ""}`;

  const datoEnv = getDatoCMSEnvironment();

  const client = new GraphQLClient(endpoint, {
    headers: {
      ...(datoEnv && { "X-Environment": datoEnv }),
    },
  });

  return client;
}
