import { logNewsletterFormSubmitSuccess } from "helpers/analyticsLogger";
import { useCallback } from "react";
import { NewsletterFragment } from "services/datocms/generated";
import { useEngagementSignupMutation } from "services/graphql/generated";

export type NewslsetterValues = {
  email: string;
  accept_marketing: boolean;
  opt_in: boolean;
};

export const useNewsletterForm = (
  newsletterData?: NewsletterFragment | null,
) => {
  const [engagementSignup, { data, error }] = useEngagementSignupMutation();

  const onSubmit = useCallback(
    async (values: { email: string }) => {
      const customData: Record<string, any> = { ...values };
      newsletterData?.customData.forEach((data) => {
        if (data.key) {
          customData[data.key] = data.value;
        }
      });

      await engagementSignup({
        variables: {
          input: {
            identifier7: {
              customData: JSON.stringify(customData),
            },
          },
        },
      });
      logNewsletterFormSubmitSuccess(customData);
    },
    [engagementSignup, newsletterData?.customData],
  );

  return { onSubmit, data, error };
};
