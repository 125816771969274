import { FC } from "react";
import st from "./MobileTopHeader.module.scss";
import Link from "next/link";

type MobileTopHeaderProps = {
  text: string;
  url: string;
  textColor: string;
  backgroundColor: string;
};

const MobileTopHeader: FC<MobileTopHeaderProps> = ({
  text,
  url,
  textColor,
  backgroundColor,
}) => {
  const styles = {
    color: textColor,
    backgroundColor,
  };
  if (!url.length) {
    return (
      <div className={st.container} style={styles}>
        <div className={st.promotionalBarText}>{text}</div>
      </div>
    );
  } else {
    return (
      <Link className={st.container} href={url} style={styles}>
        <span className={st.promotionalBarText}>{text}</span>
      </Link>
    );
  }
};

export default MobileTopHeader;
