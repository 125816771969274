import { HexColor } from "lib-types/styles";

export type colorNames =
  | "darkGray"
  | "gray"
  | "mediumGray"
  | "lightGray"
  | "borderGray"
  | "lightestGray"
  | "red"
  | "orange"
  | "green"
  | "white"
  | "black"
  | "yellow"
  | "brown"
  | "formBlue"
  | "dropdownSelectedBlue"
  | "dropdownHoverBlue"
  | "primaryDeepBlue"
  | "primaryFreshBlue"
  | "secondaryFreshBlue"
  | "secondaryDeeperSand"
  | "shade200"
  | "shade300"
  | "shade500"
  | "shade700"
  | "shade900"
  | "white";

export const colors: Record<colorNames, HexColor> = {
  darkGray: "#222",
  mediumGray: "#666",
  gray: "#999",
  lightGray: "#f6f6f6",
  borderGray: "#e5e3e7",
  lightestGray: "#bcbcbc",
  red: "#CD0002",
  orange: "#e07647",
  green: "#00883f",
  white: "#fff",
  black: "#000",
  yellow: "#f3c530",
  brown: "#8e6451",
  formBlue: "#1069d1",
  dropdownSelectedBlue: "#009CDD1A",
  dropdownHoverBlue: "#009CDD4D",
  primaryDeepBlue: "#002238",
  primaryFreshBlue: "#75a7ad",
  secondaryFreshBlue: "#e3edef",
  secondaryDeeperSand: "#e5dcd3",
  shade200: "#E5E5E5",
  shade300: "#CCCCCC",
  shade500: "#808080",
  shade700: "#4E4E4E",
  shade900: "#1A1A1A",
};
