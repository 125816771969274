import { environment } from "config";

export const getDatoCMSEnvironment = () => {
  switch (environment) {
    case "staging":
      return "staging";
    case "uat":
      return "staging";
    default:
      return null;
  }
};
