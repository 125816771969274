import { FC, SVGProps } from "react";
import { colors } from "styles/variables";

export const AlertInfoIconSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={colors.primaryDeepBlue}
    {...props}
  >
    <path d="M12.0195 3.875C9.08984 3.875 6.43359 5.4375 4.94922 7.9375C3.50391 10.4766 3.50391 13.5625 4.94922 16.0625C6.43359 18.6016 9.08984 20.125 12.0195 20.125C14.9102 20.125 17.5664 18.6016 19.0508 16.0625C20.4961 13.5625 20.4961 10.4766 19.0508 7.9375C17.5664 5.4375 14.9102 3.875 12.0195 3.875ZM12.0195 22C8.42578 22 5.14453 20.125 3.34766 17C1.55078 13.9141 1.55078 10.125 3.34766 7C5.14453 3.91406 8.42578 2 12.0195 2C15.5742 2 18.8555 3.91406 20.6523 7C22.4492 10.125 22.4492 13.9141 20.6523 17C18.8555 20.125 15.5742 22 12.0195 22ZM10.457 15.125H11.3945V12.625H10.457H9.51953V10.75H10.457H12.332H13.2695V11.6875V15.125H13.582H14.5195V17H13.582H10.457H9.51953V15.125H10.457ZM13.2695 9.5H10.7695V7H13.2695V9.5Z" />
  </svg>
);
