import {
  AnalyticsCart,
  AnalyticsData,
  CartProduct,
  EVENT_NAMES,
  FORM_SOURCE,
  FullAnalyticsData,
  FullPageViewAnalyticsData,
  LOGIN_LOCATION_ENUM,
  LogPageViewParams,
  PageViewAnalyticsData,
  PartialProduct,
  Product,
  QueryStringAttributes,
} from "@shared/types";
import { ITEM_BRAND } from "config";
import TagManager from "react-gtm-module";
import {
  AllProductsQuery,
  ProductsByGtinQuery,
} from "services/datocms/generated";
import {
  CartFragment,
  DynamicBundleFragment,
  GetItemsByGtinQuery,
  ItemByGtinQuery,
} from "services/graphql/generated";
import sha1 from "sha.js/sha1";
import URI from "urijs";
import { getScreenBreakpoint } from "./getScreenSize";

declare global {
  interface Window {
    dataLayer: (FullAnalyticsData | FullPageViewAnalyticsData)[];
  }
}

export const generatePageId = (page: string): string => {
  return new sha1().update(page).digest("hex");
};

const getItemDetails = (lineItem: any) => {
  if (lineItem.__typename === "BundleLineItem") {
    return {
      item: lineItem?.primaryBundleItems?.[0]?.item || null,
      itemImage: lineItem?.imgUrl || null,
      price: lineItem?.subtotal,
    };
  } else if (lineItem.__typename === "CartItem") {
    return {
      item: lineItem?.item || null,
      itemImage: lineItem?.item.imgUrl || null,
      price: lineItem?.item.price,
    };
  }
  return {
    item: null,
    itemImage: null,
  };
};

export const getAnalyticsCart = (
  cart: CartFragment | null,
  datoProducts:
    | AllProductsQuery["allProducts"]
    | ProductsByGtinQuery["allProducts"],
): AnalyticsCart | null => {
  if (!cart && !datoProducts) return null;

  return {
    itemCount: cart?.lineItems?.length ?? 0,
    totalValue: cart?.subtotal ? cart?.subtotal / 100 : 0,
    items: cart?.lineItems?.map((lineItem) => {
      const {
        item: { gtin, title, id },
        itemImage,
        price,
      } = getItemDetails(lineItem);
      const itemId =
        lineItem.__typename === "BundleLineItem" ? lineItem.bundleId : id;
      const itemName =
        lineItem.__typename === "BundleLineItem" ? lineItem.title : title;

      return {
        itemId,
        gtin,
        itemName,
        itemVariant: gtin,
        itemBrand: ITEM_BRAND,
        itemCategory:
          lineItem.__typename === "CartItem"
            ? datoProducts.find((product) => product.gtin === gtin)?.category
                ?.analyticsCategoryName
            : "Sparkling Water Maker",
        quantity: lineItem.quantity,
        price: (price ?? 0) / 100,
        itemImage,
      };
    }) as unknown as Product[],
  };
};

export const generateHashedId = (page: string): string => {
  return new sha1().update(page).digest("hex");
};

export const pageViewSendToDataLayer = (
  currentUserId: string | null,
  data: PageViewAnalyticsData,
  callback: (shouldLoadGtm: boolean) => void,
): void => {
  const { event, ...rest } = data;
  const user = {
    customerId: currentUserId || undefined,
  };
  const userAdornedData: FullPageViewAnalyticsData = {
    event,
    user,
    ...rest,
  };
  let shouldLoadGtm = false;
  if (!window.dataLayer) {
    shouldLoadGtm = true;
  }
  TagManager.dataLayer({ dataLayer: { page: null } });
  TagManager.dataLayer({ dataLayer: userAdornedData });
  callback(shouldLoadGtm);
};

export const sendToDataLayer = (
  currentUserId: string | null,
  data: AnalyticsData,
): void => {
  const { event, ...rest } = data;
  const userInfo = {
    email: !!currentUserId,
    username: currentUserId || undefined,
  };
  const userAdornedData: FullAnalyticsData = { event, ...userInfo, ...rest };
  TagManager.dataLayer({ dataLayer: userAdornedData });
};

/**
 * Generic catch-all event logger. Shops need to provide
 * the EVENT_NAME value.
 * @param currentUserId
 * @param event
 */
export const logEvent = (currentUserId: string | null, event: EVENT_NAMES) => {
  sendToDataLayer(currentUserId, { event });
};

export const logSignupEventSuccess = async (email: string) => {
  logEventToDataLayer({
    event: EVENT_NAMES.userSignupSuccess,
    elementType: "create_customer",
    formData: {
      email_address: email,
    },
  });
};

export const logLoginEventSuccess = async (email: string) => {
  logEventToDataLayer({
    event: EVENT_NAMES.userLoginSuccess,
    elementType: "customer_login",
    formData: {
      email_address: email,
    },
  });
};

export const logGenerateLeadOnSignupEvent = async (currentUserId: string) => {
  const params = {
    leadType: "email",
    leadSource: "account creation",
  };
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.generateLead,
    ...params,
  });
};

/**
 * When a page is loaded, this gets logged once.
 * TODO - CN write unit test for different permutations of URL possibilities
 */
export const logPageView = (
  currentUserId: string | null,
  params: LogPageViewParams,
  brand: string,
  callback: (shouldLoadGtm: boolean) => void,
): void => {
  const url = window.location.href;
  const uri = URI(url);
  const uriAttributes = {
    queryString: uri.query(),
    fragment: uri.fragment(),
    referrer: document.referrer ? document.referrer : undefined,
    referralUrl: URI(document.referrer),
    redirectCount:
      (
        window.performance.getEntriesByType(
          "navigation",
        )?.[0] as PerformanceNavigationTiming
      )?.redirectCount || 0,
    returnUrl: "",
    source: "",
    medium: "",
    campaign: "",
    term: "",
    content: "",
  };

  const parsedQueryString: QueryStringAttributes = uri.query()
    ? URI.parseQuery(uri.query())
    : {};

  const fullUrlNoHash = `${uri.pathname()}${
    uriAttributes.queryString ? "?" + uriAttributes.queryString : ""
  }`;
  let fullUrl = `${fullUrlNoHash}${
    uriAttributes.fragment ? "#" + uriAttributes.fragment : ""
  }`;

  if (
    uri.hasQuery("token", /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/) &&
    uri.hasQuery("u")
  ) {
    uriAttributes.returnUrl =
      parsedQueryString && parsedQueryString.returnUrl
        ? parsedQueryString.returnUrl
        : ""; //return url
    fullUrl = uriAttributes.returnUrl;
  }

  if (
    uriAttributes.referralUrl.hasQuery(
      "token",
      /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/,
    )
  ) {
    uriAttributes.referrer = "auth_redirect"; //don't cache url token from referral url
  }
  const { sessionAtributes } = params;

  // get allowable marketing parameters
  if (sessionAtributes && Object.keys(sessionAtributes).length > 0) {
    uriAttributes.source = sessionAtributes.utm_source
      ? decodeURIComponent(sessionAtributes.utm_source)
      : "(direct)";
    uriAttributes.medium = sessionAtributes.utm_medium
      ? decodeURIComponent(sessionAtributes.utm_medium)
      : "(none)";
    uriAttributes.campaign = sessionAtributes.utm_campaign
      ? decodeURIComponent(sessionAtributes.utm_campaign)
      : "(none)";
    uriAttributes.content = sessionAtributes.utm_content
      ? decodeURIComponent(sessionAtributes.utm_content)
      : "(none)";
    uriAttributes.term = sessionAtributes.utm_term
      ? decodeURIComponent(sessionAtributes.utm_term)
      : "(not set)";
  }

  if (uriAttributes.returnUrl.length === 0 || uriAttributes.returnUrl === "/") {
    pageViewSendToDataLayer(
      currentUserId,
      {
        event: EVENT_NAMES.pageView,
        page: {
          location: (
            params.sessionAtributes?.page_location || fullUrl
          ).toLowerCase(),
          path: window.location.pathname.toLowerCase(),
          id: params.id,
          type: params.pageType,
          title: params.pageTitle ?? "",
          breakpoint: getScreenBreakpoint(window.screen.width),
          isRedirect: uriAttributes.redirectCount > 0 ? true : false,
          brand: brand.toLowerCase(),
          pageCategory: params.pageCategory?.toLowerCase(),
          referrer: uriAttributes.referrer || undefined,
          source: uriAttributes.source,
          medium: uriAttributes.medium,
          campaign: uriAttributes.campaign,
          term: uriAttributes.term,
          content: uriAttributes.content,
          noInteraction: false,
          lang: params.lang,
          currency: params.currency,
        },
      },
      callback,
    );
  }
};

/** PRODUCT INTERACTIONS */

/**
 * Event logged when productPage is rendered the first time
 * @param item
 */
export const logProductViewedEvent = (
  currentUserId: string | null,
  item: Product | null,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.productViewed,
    item,
    noInteraction: false,
  });
};

/**
 * User clicked on a product (in the bundle) to view it
 * @param item
 */
export const logProductClickedEvent = (
  currentUserId: string | null,
  item: Product,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.bundleProductClicked,
    item,
    noInteraction: false,
  });
};

export const logEcommProductClickedEvent = (
  currentUserId: string | null,
  item: Product,
  index: number,
  itemListName: string,
  itemListId: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.bundleProductClicked,
    ecommerce: {
      itemListName,
      itemListId,
      items: [{ ...item, index: index, itemListName, itemListId }],
    },
    noInteraction: false,
  });
};

export const getEcommerceWithValue = (
  ecommerce: AnalyticsData["ecommerce"],
) => {
  const itemValue = ecommerce?.items?.reduce((acc, item) => {
    return acc + (item.price ?? 0) * (item.quantity ?? 1);
  }, 0);

  const value = itemValue ? itemValue.toFixed(2) : 0;
  return {
    ...ecommerce,
    value,
  };
};

export const logAddToCartEvent = (
  cart: AnalyticsCart,
  ecommerce: AnalyticsData["ecommerce"],
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.addToCart,
    ecommerce,
    cart,
  });
};

export const logProductImpression = (
  currentUserId: string | null,
  items: Product[],
  itemListName: string,
  itemListId: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.viewItemList,
    ecommerce: {
      itemListName,
      itemListId,
      items: items.map((item, index) => ({
        ...item,
        index: ++index,
        itemListName,
        itemListId,
      })),
    },
    noInteraction: false,
  });
};

export const logInternalPromotionView = (
  currentUserId: string | null,
  ecommerce: AnalyticsData["ecommerce"],
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.viewPromotion,
    ecommerce,
  });
};

export const logInternalPromotionSelect = (
  currentUserId: string | null,
  ecommerce: AnalyticsData["ecommerce"],
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.selectPromotion,
    ecommerce,
  });
};

/**
 * The "Add to cart" (and its variants) interacted with in a tile
 * @param currentUserId
 * @param item
 * @param qty
 * @param itemListId
 * @param itemListName
 * @param index
 */
export const logProductTileBuyButtonClickEvent = (
  currentUserId: string | null,
  item: Product,
  qty: number,
  itemListId?: string,
  itemListName?: string,
  index?: number,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.productTileBuyButton,
    item,
    qty,
    itemListId,
    itemListName,
    itemIndex: index,
    noInteraction: false,
  });
};

/** CART INTERACTIONS */

/**
 * User views cart page
 */
export const logCartView = (
  currentUserId: string | null,
  cart: AnalyticsCart | null,
): void => {
  if (cart) {
    TagManager.dataLayer({ dataLayer: { cart: null } });
    sendToDataLayer(currentUserId, {
      event: EVENT_NAMES.cartViewed,
      cart,
    });
  }
};

/**
 * User clicked on "Proceed to checkout" button in the cart
 */
export const logCartCheckoutButtonClickEvent = (
  currentUserId: string | null,
  cart: CartFragment,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.cartCheckoutButton,
    cart: { ...cart },
  });
};

/**
 * User updated quantity of item while in the cart
 * @param currentUserId
 * @param cart
 * @param product
 * @param oldQuantity
 * @param newQuantity
 * @param intervalDays if it is a number, this item is a subscription item
 */
export const logCartLineItemQtyChangedEvent = (
  currentUserId: string | null,
  cart: Partial<CartFragment>,
  product: Partial<CartProduct>,
  oldQuantity: number,
  newQuantity: number,
  intervalDays: number | null,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.cartLineItem,
    item: product,
    oldQuantity,
    newQuantity,
    intervalDays,
    noInteraction: false,
    cart,
  });
};

/** USER INTERACTIONS */

/**
 * event logged when user logs in (before the actual login event)
 * @param loginLocation
 */
export const logSignIn = (loginLocation: LOGIN_LOCATION_ENUM): void => {
  sendToDataLayer(null, {
    event: EVENT_NAMES.userLogin,
    loginLocation,
  });
};

/**
 * event logged when user chooses signs up (before the actual signup event)
 * @param signUpLocation
 */
export const logSignUp = (signUpLocation: LOGIN_LOCATION_ENUM): void => {
  sendToDataLayer(null, {
    event: EVENT_NAMES.userSignUp,
    loginLocation: signUpLocation,
  });
};

export const logPopularBrandsClickEvent = (
  currentUserId: string | null,
  popularBrand: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.popularBrandsClick,
    popularBrand,
  });
};

export const logBestSellersClickEvent = (
  currentUserId: string | null,
  bestSellerTitle: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.bestSellersClick,
    bestSellerTitle,
  });
};

export const logCategoriesClickEvent = (
  currentUserId: string | null,
  categoryTitle: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.categoryClick,
    categoryTitle,
  });
};

export const logNavigationClickEvent = (
  currentUserId: string | null,
  navigationName: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.navigationClick,
    navigationName,
  });
};

export const logModalClosed = (
  currentUserId: string | null,
  modalName: string,
): void => {
  sendToDataLayer(currentUserId, {
    event: EVENT_NAMES.modalClosed,
    modalName,
  });
};

export const logLoqateAutocompleteInitiated = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EVENT_NAMES.loqateAutocompleteInitiated,
      step: 1,
    },
  });
};

export const logLoqateAutocompleteAddressSelected = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EVENT_NAMES.loqateAutocompleteAddressSelected,
      step: 2,
    },
  });
};

export const logAddressVerified = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EVENT_NAMES.addressVerified,
      step: 3,
    },
  });
};

export const logUnverifiedAddressSelected = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EVENT_NAMES.unverifiedAddressSelected,
      step: 4,
    },
  });
};

const logEventToDataLayer = (data: AnalyticsData) => {
  const { event, ...rest } = data;
  TagManager.dataLayer({
    dataLayer: {
      eventParameters: null,
    },
  });
  TagManager.dataLayer({
    dataLayer: {
      event,
      eventParameters: rest,
    },
  });
};

export const logNewsletterFormCompleteEvent = (
  formId: string,
  leadSource: string,
): void => {
  const submissionId = (Math.random() + 10).toString(36).substring(2);

  logEventToDataLayer({
    event: EVENT_NAMES.generateLead,
    category: "lead generation",
    action: "form complete",
    nonInteraction: true,
    leadSource,
    leadType: "newsletter",
    label: submissionId,
    formId,
  });
};

export const logGetStartedFormSubmit = (
  inputValues: {
    businessName: string;
    businessSetting: string;
    orderManager: string;
    numberOfUsers: string;
    existingCustomer: boolean;
  },
  formName: string,
  formLength: number,
  formSubmitText: string,
  formSource: FORM_SOURCE,
  redirectUrl: string,
): void => {
  const submissionId = (Math.random() + 10).toString(36).substring(2);
  sendToDataLayer("", {
    event: EVENT_NAMES.formSubmit,
    form: {
      ...inputValues,
      formDestination: redirectUrl,
      formId: generateHashedId(formName),
      formLength: formLength,
      formName: formName,
      formSource: formSource,
      formSubmitText: formSubmitText,
      formType: "request",
      messageId: submissionId,
    },
  });
};

export const logGetStartedFormView = (
  formName: string,
  formLength: number,
  formSource: FORM_SOURCE,
  redirectUrl: string,
): void => {
  sendToDataLayer("", {
    event: EVENT_NAMES.formView,
    form: {
      formDestination: redirectUrl,
      formId: generateHashedId(formName),
      formLength: formLength,
      formName: formName,
      formSource: formSource,
      formType: "request",
    },
  });
};

export const logGetStartedFormExitEvent = (
  formName: string,
  formLength: number,
  formSource: FORM_SOURCE,
  redirectUrl: string,
  formStepId: string,
  formStepName: string,
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.formExit,
    form: {
      formDestination: redirectUrl,
      formExits: 1,
      formId: generateHashedId(formName),
      formType: "request",
      formLength,
      formName,
      formSource,
      formStepId,
      formStepName,
    },
  });
};

export const logNewsletterFormViewEvent = (
  formId: string,
  referringUrl: string,
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.formView,
    category: "lead generation",
    action: "form view",
    nonInteraction: true,
    label: referringUrl,
    formId,
  });
};

export const logNewsletterFormStartEvent = (
  formId: string,
  referringUrl: string,
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.formStart,
    category: "lead generation",
    action: "form start",
    label: referringUrl,
    nonInteraction: true,
    formId,
  });
};

export const logNewsletterFormFailEvent = (
  formId: string,
  message: string,
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.formFail,
    category: "lead generation",
    action: "form fail",
    label: message,
    nonInteraction: true,
    formId,
  });
};

export const logNewsletterFormSubmitSuccess = (
  formData: AnalyticsData["formData"],
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.formSubmit,
    elementType: "email-registration",
    elementId: "footer",
    formData,
  });
};

export const logNewsletterFormAbandonmentEvent = (
  formId: string,
  referringUrl: string,
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.formAbandonment,
    category: "lead generation",
    action: "form abandonment",
    label: referringUrl,
    nonInteraction: true,
    formId,
  });
};

export const logProductListImpressionEvent = (
  itemListName: string,
  currency: string | undefined,
  items: PartialProduct[],
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.productListImpression,
    ecommerce: {
      currency,
      itemListName: itemListName,
      items,
    },
  });
};

export const logSelectItemEvent = (
  itemListName: string,
  currency: string | undefined,
  items: PartialProduct[],
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.selectItem,
    ecommerce: {
      currency,
      itemListName: itemListName,
      items,
    },
  });
};

export const logMachineRegistrationSuccessEvent = (formData: {
  sodastream_model: string;
  purchase_location: string;
  purchase_date: string;
  email_address: string;
  first_name: string;
  last_name: string;
  accept_marketing: string;
}): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.machineRegistrationSuccess,
    elementType: "register-machine-form",
    elementId: formData.sodastream_model,
    formData,
  });
};

export const logProductDetailViewEvent = (
  currency: string,
  items: PartialProduct[],
  value?: number,
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.productDetailView,
    ecommerce: {
      currency,
      value,
      items,
    },
  });
};

export const logRemoveFromCartEvent = (
  cart: AnalyticsCart,
  ecommerce: AnalyticsData["ecommerce"],
): void => {
  logEventToDataLayer({
    event: EVENT_NAMES.removeFromCart,
    cart,
    ecommerce,
  });
};

export const getEventProduct = (
  pepDirectProduct:
    | ItemByGtinQuery["itemByGtin"]
    | NonNullable<GetItemsByGtinQuery["items"]>["items"][number],
  pepdirectBundle?: DynamicBundleFragment | null,
  additionalData: PartialProduct = {},
) => {
  const itemId = pepdirectBundle ? pepdirectBundle.id : pepDirectProduct?.id;
  const gtin = pepdirectBundle
    ? pepdirectBundle?.primaryComponent.items?.[0]?.gtin
    : pepDirectProduct?.gtin;
  const price =
    ((pepdirectBundle
      ? pepdirectBundle.discountPrice
      : pepDirectProduct?.price) || 0) / 100;

  return {
    itemId,
    gtin,
    itemName: pepDirectProduct?.title ?? "",
    itemBrand: ITEM_BRAND,
    price: price,
    ...additionalData,
  } as PartialProduct;
};
