import { FC, SVGProps } from "react";

export const AlertSuccessIconSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    viewBox="0 0 14 10"
    className="fill-green-600"
    {...props}
  >
    <path d="M4.78641 9.70188L0.765991 5.65542L1.69748 4.72396L4.78641 7.78685L12.3024 0.296875L13.2339 1.25438L4.78641 9.70188Z" />
  </svg>
);
