// this is an input component for the popup

import { ChangeEvent, FC, useState } from "react";
import { AllPopupsQuery } from "services/datocms/generated";

interface Props {
  inputField: AllPopupsQuery["allPopups"][number]["inputFields"][number];
}

export const PopupInput: FC<Props> = ({ inputField }) => {
  const [userInput, setUserInput] = useState("");

  const handleUserInput = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  return (
    <input
      type={inputField.inputType as "text" | "email" | "phone"}
      value={userInput}
      onChange={handleUserInput}
      className="bg-transparent pt-4 pb-4 pl-5 pr-5 rounded-full w-full outline-none border-[#8b9195] border-[1px] text-[14px]"
      placeholder={inputField.inputFieldPlaceholder || ""}
    />
  );
};
