import React, { FC } from "react";

interface CartSvgProps {
  color: string;
}

const CartSvg: FC<CartSvgProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {" "}
      <defs>
        {" "}
        <clipPath id="iplrjhdb7a">
          {" "}
          <path
            data-name="Rectangle 564"
            transform="translate(-202)"
            style={{ fill: "#fff", stroke: "#707070" }}
            d="M0 0h24v24H0z"
          ></path>{" "}
        </clipPath>{" "}
      </defs>{" "}
      <g transform="translate(202)" style={{ clipPath: "url(#iplrjhdb7a)" }}>
        {" "}
        <path
          data-name="Path 86"
          d="m148 36.9 1.423-4.2h2.888"
          transform="translate(-333.25 -28.398)"
          style={{ fill: "none", stroke: color, strokeWidth: "2px" }}
        ></path>{" "}
        <path
          data-name="Path 87"
          d="M169.86 45.6h-9.432l-2.181-6.727h13.877z"
          transform="translate(-357.213 -30.865)"
          style={{
            strokeLinecap: "round",
            fill: "none",
            stroke: color,
            strokeWidth: "2px",
          }}
        ></path>{" "}
        <g data-name="Group 3524">
          {" "}
          <path
            data-name="Path 88"
            d="M158.549 54.675a1.5 1.5 0 1 0 1.5-1.5 1.5 1.5 0 0 0-1.5 1.5z"
            transform="translate(-348.887 -35.394)"
            style={{ fill: color }}
          ></path>{" "}
          <path
            data-name="Path 89"
            d="M171.223 54.675a1.5 1.5 0 1 0 1.5-1.5 1.5 1.5 0 0 0-1.5 1.5z"
            transform="translate(-368.04 -35.394)"
            style={{ fill: color }}
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default CartSvg;
