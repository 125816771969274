import { useEffect, useState } from "react";
import { UserContextInterface } from "types/user";
// import { logEvent } from "@pepdirect/helpers/analyticsLogger";
// import { EVENT_NAMES } from "@shared/types";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  useCurrentUserLazyQuery,
  useLogoutUserMutation,
} from "services/graphql/generated";

export function useUserProvider(client: ApolloClient<NormalizedCacheObject>): {
  userContextValue: UserContextInterface;
} {
  const [logoutUser] = useLogoutUserMutation({ client });
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [getCurrentUser, { data }] = useCurrentUserLazyQuery({ client });

  useEffect(() => {
    if (!currentUserId) {
      try {
        getCurrentUser();
      } catch (e) {
        // logged in errorLink
      }
    }
  }, [currentUserId, getCurrentUser]);

  useEffect(() => {
    if (data?.currentUser?.id) {
      // only log user change if a user exists
      // logEvent(data.currentUser.id, EVENT_NAMES.userChanged);
      setCurrentUserId(data.currentUser.id);
    }
  }, [data?.currentUser]);

  const handleLogout = async () => {
    try {
      await logoutUser();
    } catch (e) {
      // logged in errorLink
    } finally {
      setCurrentUserId(null);
    }
  };

  const userContextValue: UserContextInterface = {
    currentUserId,
    logout: handleLogout,
    setUser: (userId: string) => {
      setCurrentUserId(userId);
    },
  };

  return { userContextValue };
}
