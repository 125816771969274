import { FC, SVGProps } from "react";

export const AlertWarningIconSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    className="fill-brown-700"
    {...props}
  >
    <path d="M0.651611 17.5936L11.0001 0.40625L21.3486 17.5936H0.651611ZM3.05739 16.2395H18.9428L11.0001 3.01034L3.05739 16.2395ZM10.9946 14.7892C11.2019 14.7892 11.3775 14.719 11.5214 14.5788C11.6653 14.4386 11.7373 14.2648 11.7373 14.0575C11.7373 13.8502 11.6671 13.6746 11.5269 13.5307C11.3867 13.3868 11.2129 13.3148 11.0056 13.3148C10.7983 13.3148 10.6227 13.385 10.4788 13.5252C10.3349 13.6654 10.2629 13.8392 10.2629 14.0465C10.2629 14.2538 10.3331 14.4294 10.4733 14.5733C10.6135 14.7172 10.7873 14.7892 10.9946 14.7892ZM10.323 12.0648H11.6772V7.06484H10.323V12.0648Z" />
  </svg>
);
