import cx from "classnames";
import Image from "next/image";
import Link from "next/link";
import { DesktopHeaderQuery } from "services/datocms/generated";
import ChevronDownSvg from "../../svg/ChevronDownSvg";
import st from "./BottomHeader.module.scss";

export const HeaderDropdownItem = ({
  itemData,
}: {
  itemData: NonNullable<
    DesktopHeaderQuery["desktopHeader"]
  >["leftDropdownItems"][number];
}) => {
  const title = itemData?.title?.toUpperCase() || "";

  return (
    <div className={cx(st.middleLeftItem, st.leftHoverableItem)}>
      <span>{title}</span>
      <ChevronDownSvg />

      <div className={st.dropdownMenu}>
        {itemData?.headerLeftSingleDropdown?.map((link) => {
          const { id, image, linkText, url, backgroundColor, customTextColor } =
            link;

          return (
            <Link
              className={st.dropdownImageContainer}
              href={url ?? ""}
              key={id}
              bi-type="Menu"
              bi-id={linkText}
              style={{ backgroundColor: backgroundColor?.hex ?? "transparent" }}
            >
              <span
                className={cx(st.dropdownImageText, "black")}
                style={{ color: customTextColor ?? "black" }}
              >
                {linkText}
              </span>
              <Image
                width={190}
                height={190}
                className="rounded-[25px]"
                src={image?.url ?? ""}
                alt={image?.alt ?? ""}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
