import { useCallback, useEffect, useState } from "react";
import client from "services/graphql/client";
import {
  LocalizationQuery,
  useLocalizationLazyQuery,
} from "services/graphql/generated";
import { formatToLocaleCurrency } from "../../helpers/currency";

export interface LocalizationContextInterface {
  formatPriceByLocale: (
    price?: number | null,
    showSymbol?: boolean,
    showZero?: boolean,
  ) => string;
  localization: LocalizationQuery["localization"];
}

export function useLocalizationProvider(defaultCurrencyCode: string): {
  localizationContextValue: LocalizationContextInterface;
} {
  const [localization, setLocalization] = useState<
    LocalizationQuery["localization"] | null
  >(null);
  const [getLocalization] = useLocalizationLazyQuery({ client });

  useEffect(() => {
    const handleGetLocalization = async () => {
      const { data } = await getLocalization();
      if (data?.localization) setLocalization(data.localization);
    };
    if (!localization) handleGetLocalization();
  }, [localization, getLocalization]);

  // TODO: change function props to be an object to avoid calls like (price, true, true)
  const formatPriceByLocale = useCallback(
    (price?: number | null, showSymbol = true, showZero = false): string => {
      if (price == null || (!showZero && price === 0)) return "-";

      return formatToLocaleCurrency({
        defaultCurrencyCode: defaultCurrencyCode || "USD",
        locale: localization?.locale || "en",
        showSymbol,
        valueInCentsDenomination: price,
      });
    },
    [defaultCurrencyCode, localization?.locale],
  );

  const localizationContextValue: LocalizationContextInterface = {
    formatPriceByLocale,
    localization,
  };

  return { localizationContextValue };
}
