import React from "react";

const MobileMenuBarSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          style={{ fill: "none", stroke: "#efebe4", strokeWidth: "2px" }}
          d="M0 5h24"
        ></path>
        <path
          style={{ fill: "none", stroke: "#efebe4", strokeWidth: "2px" }}
          d="M0 12h17"
        ></path>
        <path
          style={{ fill: "none", stroke: "#efebe4", strokeWidth: "2px" }}
          d="M0 19h11"
        ></path>
      </g>
    </svg>
  );
};

export default MobileMenuBarSvg;
