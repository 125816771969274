'use client';
 
export default function myImageLoader({ src, width, quality }) {
    if(src && src.toLowerCase().includes("datocms-assets")) {
        if(width) {
            const newUrl = `${src}?w=${width}&auto=format&q=${quality || "75"}`;
            return newUrl;
        }
    }
    return src;
}