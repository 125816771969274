import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface NewButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: "filled" | "outlined";
  size?: "large" | "medium" | "small";
  fullWidth?: boolean;
}

export const NewButton: FC<NewButtonProps> = ({
  children,
  className,
  variant = "filled",
  size = "large",
  fullWidth,
  disabled,
  ...restProps
}) => {
  return (
    <button
      disabled={disabled}
      className={twMerge(
        "inline-flex  hover:opacity-85 h-12 rounded-full font-semibold justify-center items-center flex-nowrap w-fit leading-[21px] hover:bg-[#112340]",
        variant === "filled" && "bg-primary text-white",
        variant === "outlined" &&
          "bg-white text-primary border border-primary hover:text-white hover:bg-primary",
        size === "large" && "text-base px-8",
        size === "medium" && "py-4",
        size === "small" && "px-4 h-[30px] text-[13px]",
        disabled && "!bg-[#ccc] border-none text-white",
        fullWidth && "!w-full",
        className,
      )}
      {...restProps}
    >
      {children}
    </button>
  );
};
