import { AlertErrorIconSvg } from "components/icons/Alert/AlertErrorIconSvg";
import { AlertInfoIconSvg } from "components/icons/Alert/AlertInfoIconSvg";
import { AlertSuccessIconSvg } from "components/icons/Alert/AlertSuccessIconSvg";
import { AlertWarningIconSvg } from "components/icons/Alert/AlertWarningIconSvg";
import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type AlertType = "info" | "error" | "warning" | "success";

type AlertProps = {
  type: AlertType;
  children: ReactNode;
  fullWidth?: boolean;
  hideBackground?: boolean;
};

export const Alert: FC<AlertProps> = ({
  type,
  fullWidth,
  children,
  hideBackground,
}) => {
  return (
    <div
      className={twMerge(
        "flex gap-2.5 p-3 rounded-[5px] font-secondary font-bold text-[13px]",
        fullWidth && "w-full",
        type === "success" && "bg-green-100 text-green-600",
        type === "error" && "bg-red-100 text-red-500",
        type === "warning" && "bg-brown-100 text-brown-700",
        type === "info" && "bg-[#E3EDEF] text-primary",
        hideBackground && "bg-transparent px-0",
      )}
    >
      <AlertIcon type={type} />
      {children}
    </div>
  );
};

const AlertIcon: FC<{ type: AlertType }> = ({ type }) => {
  switch (type) {
    case "success":
      return <AlertSuccessIconSvg />;
    case "error":
      return <AlertErrorIconSvg />;
    case "warning":
      return <AlertWarningIconSvg />;
    case "info":
      return <AlertInfoIconSvg />;
    default:
      return null;
  }
};
