import { FC, SVGProps } from "react";
import styled from "styled-components";

export enum PagePointDirections {
  up = "up",
  down = "down",
}

const pointDirectionTransformMap = {
  [PagePointDirections.down]: "transform: none",
  [PagePointDirections.up]: "transform: rotate(180deg)",
};

interface ChevronSvgProps extends SVGProps<SVGSVGElement> {
  pointDirection: PagePointDirections;
}

const StyledSvg = styled.svg<{ pointDirection: PagePointDirections }>`
  ${({ pointDirection }) => pointDirectionTransformMap[pointDirection]};
`;

export const ChevronSvg: FC<ChevronSvgProps> = ({
  height,
  width,
  stroke,
  pointDirection,
}) => (
  <StyledSvg
    pointDirection={pointDirection}
    width={height || "9"}
    height={width || "6"}
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector 63"
      d="M1 1L4.5 5L8 1"
      stroke={stroke || "#005D6E"}
      strokeLinecap="round"
    />
  </StyledSvg>
);
