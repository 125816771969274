import { isServerSide } from "helpers/routing";
import { createDatoClient } from "services/datocms";
import client from "services/graphql/client";
import * as Sentry from "@sentry/nextjs";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  AllPopupsQuery,
  DesktopHeaderQuery,
  FooterQuery,
  MobileHeaderQuery,
  TranslationQuery,
  getSdk,
} from "services/datocms/generated";
import {
  LocalizationDocument,
  LocalizationQuery,
  LocalizationQueryVariables,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
} from "services/graphql/generated";
import { createQueryParamsForCaching } from "utils/create-query-params";
import createSsrClient from "services/graphql/ssr-client";
import { AppContext as NextAppContext } from "next/app";

export type StartupData = {
  footerData: FooterQuery["footer"];
  headerData: DesktopHeaderQuery["desktopHeader"];
  locale: string;
  mobileHeader: MobileHeaderQuery["mobileHeader"];
  popups: AllPopupsQuery["allPopups"];
  tenant: TenantQuery;
  translation: TranslationQuery["translation"];
};

type StartupCachedData = Omit<StartupData, "locale" | "tenant">;

let cache: StartupCachedData | null = null;
let lastFetchTime = 0;
const CACHE_TTL = 1000 * 60 * 5; // 5 min

export const getStartupData = async (
  appContext: NextAppContext,
): Promise<StartupData> => {
  let gqlClient: ApolloClient<NormalizedCacheObject>;
  if (isServerSide(appContext)) {
    /**
     * Before, we determined if we need to pass cookies or not based on success of user query.
     * Apparently, for some reason user query always fails and we clean cookies in any case
     * AND we don't need cookies in tennant/featureFlags calls anyway, so why bother and create waterfall?
     */
    gqlClient = createSsrClient(appContext?.ctx?.req, true);
  } else {
    gqlClient = client;
  }

  // don't cache any pepdirect queries
  const [localization, tenantData] = await Promise.all([
    gqlClient.query<LocalizationQuery, LocalizationQueryVariables>({
      query: LocalizationDocument,
    }),
    gqlClient.query<TenantQuery, TenantQueryVariables>({
      query: TenantDocument,
    }),
  ]);

  const locale = localization?.data?.localization?.locale || "en";

  if (cache) {
    const isCacheExpired = Date.now() - lastFetchTime >= CACHE_TTL;
    if (!isCacheExpired) {
      // return cached data
      return {
        locale,
        tenant: tenantData.data,
        ...cache,
      };
    } else {
      // cache is expired, resetting cache and fetching real data
      cache = null;
    }
  } else {
    // no cache available, fetching real data
  }

  const [
    { mobileHeader },
    { desktopHeader },
    { footer },
    { translation },
    { allPopups },
  ] = await Promise.all([
    getSdk(
      createDatoClient(
        appContext.router.isPreview,
        createQueryParamsForCaching({ queryType: "MobileHeader" }),
      ),
    ).MobileHeader(),
    getSdk(
      createDatoClient(
        appContext.router.isPreview,
        createQueryParamsForCaching({ queryType: "DesktopHeader" }),
      ),
    ).DesktopHeader(),
    getSdk(
      createDatoClient(
        appContext.router.isPreview,
        createQueryParamsForCaching({ queryType: "Footer" }),
      ),
    ).Footer(),
    getSdk(
      createDatoClient(
        appContext.router.isPreview,
        createQueryParamsForCaching({ queryType: "Translation" }),
      ),
    ).Translation(),
    getSdk(
      createDatoClient(
        appContext.router.isPreview,
        createQueryParamsForCaching({ queryType: "AllPopups" }),
      ),
    ).AllPopups(),
  ]);

  const result: StartupCachedData = {
    footerData: footer,
    headerData: desktopHeader,
    mobileHeader,
    popups: allPopups ?? [],
    translation,
  };

  // only cache results if we have all the data
  if (
    result.translation &&
    result.popups &&
    result.footerData &&
    result.headerData &&
    result.mobileHeader
  ) {
    cache = result;
    lastFetchTime = Date.now();
  } else {
    Sentry.captureException("Error fetching startup data.", {
      extra: { result },
    });
  }

  return {
    locale,
    tenant: tenantData.data,
    ...result,
  };
};
