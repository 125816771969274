import {
  AnalyticsSessionParamsContextType,
  AnalyticsSessionParamsType,
} from "@shared/types";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useEffect, useState } from "react";

function getAllowedParams() {
  const allowedParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];
  return allowedParams;
}

export function useAnalyticsSessionParamsProvider(): {
  analyticsParamsValue: AnalyticsSessionParamsContextType;
} {
  const SESSION_STORAGE_KEY = "analyticsparams";
  const [analyticsParams, setAnalyticsParams] =
    useState<AnalyticsSessionParamsType | null>(null);
  const router = useRouter();
  useEffect(() => {
    const params: ParsedUrlQuery = {};
    Object.keys(router.query).forEach((param) => {
      if (getAllowedParams().includes(param)) {
        params[param] = router.query[param] as string;
      }
    });

    const sessionStorageItem = sessionStorage.getItem(SESSION_STORAGE_KEY);
    params["page_location"] = window.location.href;

    if (!sessionStorageItem) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(params));
      setAnalyticsParams(params);
    } else {
      setAnalyticsParams(
        JSON.parse(sessionStorageItem) as AnalyticsSessionParamsType,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analyticsParamsValue = {
    analyticsSessionParams: analyticsParams,
  };

  return { analyticsParamsValue: analyticsParamsValue };
}
