import { FC } from "react";
import { StructuredText, StructuredTextDocument } from "react-datocms";

interface Props {
  showCheckbox?: boolean;
  legalText: StructuredTextDocument;
}

export const CustomLegalText: FC<Props> = ({ showCheckbox, legalText }) => {
  return (
    <div className="flex flex-row items-start mt-[10px]">
      {showCheckbox && (
        <input type="checkbox" className="scale-150 mt-1 mr-[10px]" />
      )}
      <div className="text-xs">
        <StructuredText data={legalText} />
      </div>
    </div>
  );
};
