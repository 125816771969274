import {
  AnalyticsCart,
  LogContextValueInterface,
  LogPageViewParams,
  Product,
} from "@shared/types";
import { ITEM_BRAND, isProductionReady } from "config";
import { AnalyticsSessionParamsContext } from "context/analyticsSessionParams";
import {
  logCartView,
  logPageView,
  logProductViewedEvent,
} from "helpers/analyticsLogger";
import { useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";

export function useLogProvider(
  currentUserId: string | null,
  gtmId?: string | null,
  locale?: string | null,
  currency?: string | null,
): LogContextValueInterface {
  /**
   * Wrapper for logPageView, so we don't need to
   * write useEffect on every page.
   * @param key some unique id to identify page,
   * events are expected in a specific order given below
   * pageView -> gtm.js -> productView/cartView
   * for example, page url/slug/whatnot,
   * otherwise it won't trigger when for example
   * route changes from/collections/kit-1 to /collections/kit-2
   */
  const useLogPageView = (
    params: LogPageViewParams,
    product?: Product | null,
    cart?: AnalyticsCart | null,
    key?: string,
  ): void => {
    const [pageViewLoaded, setPageViewLoaded] = useState(false);
    const { analyticsSessionParams } = useContext(
      AnalyticsSessionParamsContext,
    );

    const loadGtm = (shouldLoadGtm: boolean) => {
      gtmId &&
        isProductionReady &&
        shouldLoadGtm &&
        TagManager.initialize({ gtmId });
    };

    useEffect(() => {
      if (analyticsSessionParams) {
        logPageView(
          currentUserId,
          {
            ...params,
            lang: locale ?? "",
            currency: currency ?? "",
            pageTitle: typeof document !== "undefined" ? document.title : "",
            sessionAtributes: analyticsSessionParams,
          },
          ITEM_BRAND,
          loadGtm,
        );
        setPageViewLoaded(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, analyticsSessionParams]);

    useEffect(() => {
      if (pageViewLoaded && cart) {
        logCartView(currentUserId, cart);
      }
    }, [cart, pageViewLoaded]);

    useEffect(() => {
      if (product && pageViewLoaded) {
        logProductViewedEvent(currentUserId, product);
      }
    }, [product, pageViewLoaded]);
  };

  return { useLogPageView };
}
