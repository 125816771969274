import { FC, useEffect, useState } from "react";
import { StructuredText, StructuredTextDocument } from "react-datocms";
import { AllPopupsQuery } from "services/datocms/generated";
import CloseSvg from "../svg/CloseSvg";

import { isEmptyDocument } from "datocms-structured-text-utils";
import Image from "next/image";
import { CustomLegalText } from "./CustomLegalText";
import { PopupInput } from "./PopupInput";

interface Props {
  popup: AllPopupsQuery["allPopups"][0];
}

export const Popup: FC<Props> = ({ popup }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isWelcomeTextVisible, setIsWelcomeTextVisible] = useState(false);

  useEffect(() => {
    setTimeout(
      () => {
        setIsPopupVisible(false);
      },
      popup?.showPopupAfter * 1000,
    );
  }, [popup?.showPopupAfter]);

  const handleClickCTA = () => {
    if (isEmptyDocument(popup?.welcomeText)) {
      setIsPopupVisible(false);
    } else {
      setIsWelcomeTextVisible(true);
    }
  };

  return isPopupVisible ? (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={() => setIsPopupVisible(false)}
      />
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 ">
        <div className="bg-white rounded-3xl overflow-hidden shadow-lg mx-auto flex flex-row justify-around relative w-11/12 md:w-4/5 lg:w-5/12 min-h-[50vh]">
          <button
            className={`absolute top-4 ${
              popup?.imageOnLeftSide ? "right-4" : "left-4"
            } text-lg font-semibold`}
            onClick={() => setIsPopupVisible(false)}
          >
            <CloseSvg color="#000" />
          </button>
          {popup?.imageOnLeftSide && (
            <Image
              src={popup?.heroImage?.url || ""}
              alt="secondary article cover"
              className="object-cover rounded-l-3xl hidden md:w-2/5 md:block"
              width={0}
              height={0}
              sizes="100vw"
            />
          )}
          <div
            className={`text-left p-10 flex justify-around ${
              isWelcomeTextVisible ? "md:justify-center" : "md:justify-between"
            } flex-col w-full`}
          >
            {isWelcomeTextVisible ? (
              <div>
                <StructuredText data={popup?.welcomeText?.value} />
              </div>
            ) : (
              <>
                <div>
                  <StructuredText
                    data={popup?.mainText as { value: StructuredTextDocument }}
                    renderBlock={({ record }) => (
                      <CustomLegalText
                        legalText={record?.legalText as StructuredTextDocument}
                        showCheckbox={!!record?.showCheckbox}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-[10px] my-[10px]">
                  {popup?.inputFields.map((field, index) => (
                    <PopupInput key={index} inputField={field} />
                  ))}
                </div>
                <div>
                  <button
                    className="bg-primary font-semibold text-white h-16 rounded-full p-2 w-full mb-5"
                    onClick={handleClickCTA}
                  >
                    {popup?.buttonText}
                  </button>
                  {popup?.disclaimerText && (
                    <div className="text-xs">
                      <StructuredText data={popup?.disclaimerText.value} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {!popup?.imageOnLeftSide && (
            <Image
              src={popup?.heroImage?.url || ""}
              alt="secondary article cover"
              className="object-cover rounded-r-3xl w-2/5"
              width={0}
              height={0}
              sizes="100vw"
            />
          )}
        </div>
      </div>
    </>
  ) : null;
};
