import cx from "classnames";
import { ChevronSvg, PagePointDirections } from "components/icons/ChevronSvg";
import Link from "next/link";
import { FC, useState } from "react";
import { Image } from "react-datocms/image";
import { StructuredText } from "react-datocms/structured-text";
import { FooterQuery } from "services/datocms/generated";
import SodastreamLogo from "../svg/SodastreamLogo";
import st from "./Footer.module.scss";
import { FooterNewsletter } from "./FooterNewsletter";

type LinksColumnProps = {
  initiallyCollapsed?: boolean;
  headingText: string;
  links?: { title?: string | null; url?: string | null }[];
};

const LinksColumn: FC<LinksColumnProps> = ({
  headingText,
  links,
  initiallyCollapsed = true,
}) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  return (
    <div
      className={`${st.rightContainerSingleContent} ${
        collapsed ? st.collapsed : ""
      }`}
    >
      <div
        className={st.rightContainerSingleContentHeaderText}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <div>{headingText}</div>
        <div className={st.expansionIcon}>
          <ChevronSvg
            stroke="white"
            height={20}
            width={20}
            pointDirection={
              collapsed ? PagePointDirections.down : PagePointDirections.up
            }
          />
        </div>
      </div>
      {links &&
        links.map((item) => (
          <div
            key={item.title}
            className={st.rightContainerSingleContentListItem}
          >
            <Link target="_blank" href={item.url || ""}>
              {item.title}
            </Link>
          </div>
        ))}
    </div>
  );
};

type FooterProps = {
  footerData: FooterQuery["footer"];
};

const Footer: FC<FooterProps> = ({ footerData }) => {
  const {
    firstColumnHeading,
    firstColumnLinks,
    secondColumnHeading,
    secondColumnLinks,
    thirdColumnHeading,
    thirdColumnLinks,
    newsletter,
    socialMediaLinks = [],
  } = footerData || {};
  return (
    <div className={st.container}>
      <div className={cx(st.innerContainer, "container")}>
        <div className="flex-col justify-start items-start gap-10 flex md:basis-1/4">
          <SodastreamLogo color="white" width="198px" />
          {newsletter && <FooterNewsletter newsletter={newsletter} />}
          <div className="text-[13px] font-primary">
            <StructuredText data={footerData?.copyrightInfo?.value} />
          </div>
          <div className="justify-start items-start gap-2 flex">
            {socialMediaLinks.map((item) => (
              <Link target="_blank" key={item.id} href={item.url ?? ""}>
                {item.image?.responsiveImage && (
                  <Image data={item.image?.responsiveImage} />
                )}
              </Link>
            ))}
          </div>
        </div>
        <div className={st.rightContainer}>
          {firstColumnHeading && (
            <LinksColumn
              headingText={firstColumnHeading}
              links={firstColumnLinks}
              initiallyCollapsed={false}
            />
          )}
          {secondColumnHeading && (
            <LinksColumn
              headingText={secondColumnHeading}
              links={secondColumnLinks}
            />
          )}
          {thirdColumnHeading && (
            <LinksColumn
              headingText={thirdColumnHeading}
              links={thirdColumnLinks}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
