import { useState, useEffect } from "react";
import { TenantQuery } from "services/graphql/generated";
import { AppContextInterface } from "types/appContext";

export function useAppProvider(
  tenant: TenantQuery["tenant"]
): { appContextValue: AppContextInterface } {
  const [tenantState, setTenantState] = useState<TenantQuery["tenant"]>(null);

  useEffect(() => {
    setTenantState(tenant);
  }, [tenant]);

  const appContextValue: AppContextInterface = {
    tenant: tenantState,
  };

  return { appContextValue };
}
